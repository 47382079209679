import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ageAgo } from "./../../../helper/helper";
import constant from "./../../../helper/constant";

const QueryItem = (props) => {
  const { result, agent_id, user_input, timestamp } = props.query;

  const rennderQuestion = () => {
    return (
      <div className="chat-query-history-question d-flex justify-content-between align-align-items-center font-weight-bold">
        <p className="p-0">{ageAgo(timestamp, constant.dateTimeFormat)}</p>
        <div className="d-flex flex-row align-items-center justify-content-end gap-1 px-2">
          <FontAwesomeIcon className="" icon={faQuestionCircle} />
          <p className="p-0 m-0">{user_input}</p>
        </div>
      </div>
    );
  };

  const renderQueryAnwser = () => {
    if (result && result.reason) {
      return (
        <>
          <p
            className="p-0"
            dangerouslySetInnerHTML={{
              __html: result.reason,
            }}
          ></p>
          {result.URL && (
            <Link
              className=""
              href={result.URL}
              target="_blank"
              rel="noreferrer"
            >
              {result.URL}
            </Link>
          )}
        </>
      );
    }
  };
  const render = () => {
    return (
      <div
        className={`chtbot-query-item chat-text-left gap-2 d-flex flex-column px-2 text-justify`}
      >
        {rennderQuestion()}
        {renderQueryAnwser()}
      </div>
    );
  };

  return render();
};

export default QueryItem;
