// COMMON Types
export const UPDATE_LOADER = "UPDATE_LOADER";
export const UPDATE_LEFT_MENU = "UPDATE_LEFT_MENU";
export const UPDATE_RIGHT_MENU = "UPDATE_RIGHT_MENU";
export const CLEAR_MODEL_INFO = "CLEAR_MODEL_INFO";

//User Types
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const GET_INDUSTRIES = "GET_INDUSTRIES";
export const CALCULATE_UNSIGNED_REVENUE_SUCCESS =
  "CALCULATE_UNSIGNED_REVENUE_SUCCESS";
export const CLEAR_UNSIGNED_REVENUE = "CLEAR_UNSIGNED_REVENUE";
export const GET_UNSIGNED_REPORT_BY_MAIL_SUCCESS =
  "GET_UNSIGNED_REPORT_BY_MAIL_SUCCESS";
export const CREATE_ORG_SUCCESS = "CREATE_ORG_SUCCESS";
export const UPDATE_ORG_INFO = "UPDATE_ORG_INFO";
export const SET_SELECTED_INDUSTRY = "SET_SELECTED_INDUSTRY";
export const UPDATE_REVENUE_REPORT = "UPDATE_REVENUE_REPORT";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const USER_SIGNUP = "USER_SIGNUP";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE";

//AI TEMPLATES
export const AI_STRATEGY_GET_TEMPLATES = "AI_STRATEGY_GET_TEMPLATES";
export const AI_STRATEGY_GET_TEMPLATES_SUCCESS =
  "AI_STRATEGY_GET_TEMPLATES_SUCCESS";
export const AI_STRATEGY_GET_TEMPLATES_FAILURE =
  "AI_STRATEGY_GET_TEMPLATES_FAILURE";
export const AI_STRATEGY_SET_ACTIVE_TEMP_ID = "AI_STRATEGY_SET_ACTIVE_TEMP_ID";
export const AI_STRATEGY_UPDATE_SELECTED_TEMPLATE =
  "AI_STRATEGY_UPDATE_SELECTED_TEMPLATE";
export const AI_STRATEGY_SET_ACTIVE_TEMPLATE =
  "AI_STRATEGY_SET_ACTIVE_TEMPLATE";
export const AI_STRATEGY_UPDATE_TEMPLATE_NAME =
  "AI_STRATEGY_UPDATE_TEMPLATE_NAME";

//AI MODEL Types
export const AI_STRATEGY_SET_MODELS = "AI_STRATEGY_SET_MODELS";
export const AI_STRATEGY_GET_DEPARTMENTS = "AI_STRATEGY_GET_DEPARTMENTS";
export const AI_STRATEGY_GET_TASKS = "AI_STRATEGY_GET_TASKS";
export const AI_STRATEGY_SHOW_DEPARTMENT = "AI_STRATEGY_SHOW_DEPARTMENT";
export const AI_STRATEGY_SAVE_MODEL = "AI_STRATEGY_SAVE_MODEL";
export const AI_STRATEGY_ADD_MODEL =
  "AI_STRAAI_STRATEGY_ADD_MODELTEGY_SAVE_MODEL";
export const AI_STRATEGY_DELETE_MODEL = "AI_STRATEGY_DELETE_MODEL";
export const AI_STRATEGY_ON_SELECT_MODEL = "AI_STRATEGY_ON_SELECT_MODEL";
export const AI_STRATEGY_SHOW_MODELS = "AI_STRATEGY_SHOW_MODELS";
export const AI_STRATEGY_DISABLE_MODELS = "AI_STRATEGY_DISABLE_MODELS";
export const AI_STRATEGY_RESET_MODEL_STATUS = "AI_STRATEGY_RESET_MODEL_STATUS";
export const AI_STRATEGY_SHOW_VALUE_ADD = "AI_STRATEGY_SHOW_VALUE_ADD";
export const AI_STRATEGY_CLOSE_DROPDOWN_FORM =
  "AI_STRATEGY_CLOSE_DROPDOWN_FORM";
export const AI_STRATEGY_CALCULATE_VALUE = "AI_STRATEGY_CALCULATE_VALUE";

//AI VALUE Types
export const AI_STRATEGY_GET_AI_VALUES_SUCCESS =
  "AI_STRATEGY_GET_AI_VALUES_SUCCESS";

//AI ROADMAP Types
export const AI_STRATEGY_GET_AI_ROADMAP_SUCCESS =
  "AI_STRATEGY_GET_AI_ROADMAP_SUCCESS";

//AI DATA Types
export const AI_STRATEGY_SET_AI_DATA_STATUS = "AI_STRATEGY_SET_AI_DATA_STATUS";
export const AI_STRATEGY_SET_AI_PREDICT_LOOKUP =
  "AI_STRATEGY_SET_AI_PREDICT_LOOKUP";
export const AI_STRATEGY_UPDATE_AI_PREDICTION_DATA =
  "AI_STRATEGY_UPDATE_AI_PREDICTION_DATA";

//AI MODEL Types
export const AI_STRATEGY_SET_AI_MODEL_STATUS =
  "AI_STRATEGY_SET_AI_MODEL_STATUS";
export const AI_STRATEGY_SET_AI_MODEL_ON_SELECT_MODEL =
  "AI_STRATEGY_SET_AI_MODEL_ON_SELECT_MODEL";
export const AI_STRATEGY_SET_AI_MODEL_SAVE_AI_DATA =
  "AI_STRATEGY_SET_AI_MODEL_SAVE_AI_DATA";
export const AI_STRATEGY_AI_DATA_WORKFLOW_OPTIONS_SUCCESS =
  "AI_STRATEGY_AI_DATA_WORKFLOW_OPTIONS_SUCCESS";
export const AI_STRATEGY_AI_DATA_TARGET_OPTIONS_SUCCESS =
  "AI_STRATEGY_AI_DATA_TARGET_OPTIONS_SUCCESS";
export const AI_STRATEGY_AI_DATA_FEATURE_OPTIONS_SUCCESS =
  "AI_STRATEGY_AI_DATA_FEATURE_OPTIONS_SUCCESS";

//AI PREDICT Types
export const AI_STRATEGY_SET_AI_PREDICT_SHOW_STATUS =
  "AI_STRATEGY_SET_AI_PREDICT_SHOW_STATUS";
export const AI_PREDICT_UPDATE_MODEL = "AI_PREDICT_UPDATE_MODEL";

//AI SOCIAL_MEDIA
export const AI_SM_CREATE_AD_CONTENT = "AI_SM_CREATE_AD_CONTENT";
export const AI_SM_GET_REVIEW_KEYWORDS = "AI_SM_GET_REVIEW_KEYWORDS";
export const AI_SM_CREATE_AD_IMAGES = "AI_SM_CREATE_AD_IMAGES";
export const AI_SM_CLEAR = "AI_SM_CLEAR";

//AI Image Classification
export const SET_SELECTED_AI_IMAGE_CLASSIFICATION =
  "SET_SELECTED_AI_IMAGE_CLASSIFICATION";
export const SET_AI_IMAGE_CLASSIFICATION = "SET_AI_IMAGE_CLASSIFICATION";
export const REMOVE_AI_IMAGE_CLASSIFICATION = "REMOVE_AI_IMAGE_CLASSIFICATION";
export const UPDATE_AUTO_CREATE_FLOW = "UPDATE_AUTO_CREATE_FLOW";

//AI Business overview
export const UPDATE_OVERVIEW_FIRST_TIME = "UPDATE_OVERVIEW_FIRST_TIME";

//AI ASK ME ANYTHING
export const UPDATE_QUERY_HISTORY = "UPDATE_QUERY_HISTORY";
export const UPDATE_CURRENT_QUERY = "UPDATE_CURRENT_QUERY";
export const UPDATE_CURRENT_QUERY_TASKS = "UPDATE_CURRENT_QUERY_TASKS";
export const UPDATE_CURRENT_QUERY_WORKFLOWS = "UPDATE_CURRENT_QUERY_WORKFLOWS";
export const UPDATE_SHOW_MODAL = "UPDATE_SHOW_MODAL";
export const UPDATE_ASK_ME_SELECTED_MODEL = "UPDATE_ASK_ME_SELECTED_MODEL";
export const CLEAR_ASK_ME = "CLEAR_ASK_ME";
export const UPDATE_ACTIVE_AGENT = "UPDATE_ACTIVE_AGENT";
export const UPDATE_ACTIVE_UUID = "UPDATE_ACTIVE_UUID";
