import React, { useEffect, useState } from "react";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import { getUUID } from "../../../helper/helper";
import images from "../../../helper/images";
import {
  postChatboxQuestion,
  getChatboxQuestionDetails,
} from "../../../service/ai-model.service";
import QueryItem from "./QueryItem";

const ChatQueyResult = (props) => {
  const {
    currentQuery,
    activeAgent,
    showModal,
    activeUUID,
    updateCurrentQuery,
  } = useAIAskMeContext();
  const [queryList, setQueryList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showModal && currentQuery) {
      setLoading(true);
      const payload = {
        uuid: activeUUID || getUUID(),
        user_input: currentQuery,
        agent_id: activeAgent,
      };
      postChatboxQuestion(payload)
        .then((data) => {
          const list = queryList.slice();
          const item = {
            uuid: payload.uuid,
            user_input: payload.user_input,
            agent_id: activeAgent,
          };
          if (data.RAG) {
            data.reason = data.RAG;
          }
          item.result = data;
          list.push(item);
          setQueryList(list);
        })
        .catch((ex) => ex)
        .finally((ex) => setLoading(false));
    } else {
      setQueryList([]);
    }
  }, [currentQuery, showModal]);

  useEffect(() => {
    if (activeUUID) {
      setQueryList([]);
      setLoading(true);
      getChatboxQuestionDetails({ uuid: activeUUID })
        .then((data) => {
          if (
            data &&
            data.result &&
            data.result.response &&
            data.result.response.length
          ) {
            setQueryList(data.result.response);
          }
        })
        .catch((e) => e)
        .finally(() => setLoading(false));
    }
  }, [activeUUID]);

  const renderQueryList = () => {
    if (showModal && queryList && queryList.length)
      return queryList.map((query, index) => {
        return <QueryItem key={index} query={query} index={index} />;
      });
  };

  const renderInlineLoader = () => {
    return (
      <div className="ask-me-loader-container">
        <img src={images.loaderGif} width="50px" alt="" />
      </div>
    );
  };

  const renderQueryResults = () => {
    return (
      <div className="chtbot-results">
        <h3 className="text-white pb-3">Query Results</h3>
        {renderQueryList()}
        {loading && renderInlineLoader()}
      </div>
    );
  };
  const render = () => {
    return (
      <div className="chtbotmidsec text-white">{renderQueryResults()}</div>
    );
  };
  return render();
};

export default ChatQueyResult;
