import axios from "axios";
import ErrorInterceptor from "./interceptors/error.interceptor";
import HttpRequestInterceptor from "./interceptors/http.request.interceptor";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
});

export const askMeHttp = axios.create({
  baseURL: `${process.env.REACT_APP_AKYME_API_URL}/`,
});

HttpRequestInterceptor(http);
ErrorInterceptor(http);

HttpRequestInterceptor(askMeHttp);
ErrorInterceptor(askMeHttp);

export default http;
